* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-touch-callout: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&:focus {
		outline: none;
	}
}

*::before,
*::after {
	box-sizing: border-box;
}

html,
body {
	min-height: 100vh;
    width: 100%;
}

html {
	font-size: 100%;
}

body {
    align-items: center;
	background: $black;
	color: $white;
	display: flex;
	flex-direction: column;
    justify-content: center;
	position: relative;
	text-align: center;

	padding: 2rem 2rem 3rem;
}

header {
	margin-bottom: 1.5rem;
}

main {
	max-width: 810px;
	width: 85%;

	p {
		margin: 1rem 0 2rem;

		a {
			border-bottom: 1.5px solid currentColor;
	
			&:hover {
				border-bottom-color: #2e2e2e;
				opacity: 1;
			}
		}
	}

	ul {
		li {
			margin: 0 1rem;

			span {
				color: #898989;
				margin: 0 0.25rem;
				opacity: 0.65;

				&:first-child {
    				margin: 0 0.5rem;
				}
			}
		}
	}
}

main,
footer {
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		width: 100%;

		li {
			display: inline;
		}

		a {
			white-space: nowrap;
		}
	}
}

footer {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 1rem;

	a {
		color: #898989;
		margin: 0 0.3rem;
		opacity: 0.4;
	}
}

a {
	color: currentColor;
	text-decoration: none;
	transition: all 0.2s $ease;

	&:hover {
		opacity: 0.75;
	}
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
	vertical-align: middle;
}

img {
	display: block;
	height: auto;
	max-width: 100%;
}

video {
	width: 100%;
	height: auto;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

input,
textarea,
select,
button {
	font: inherit;
	border: none;
	color: inherit;
	line-height: inherit;
	background: none;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
	resize: vertical;
}

/*
 * A better looking default horizontal rule
 */

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}

iframe {
	border: 0;
	margin: 1.4em 0;
	max-width: 100%;
}

address {
	font-style: normal;
}
