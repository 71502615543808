@mixin text-small {
	font-size: 0.76em;
}

@mixin text-medium {
	font-size: 0.875em;
}

@mixin text-large {
	font-size: 1.35em;
	line-height: 1.4;
	letter-spacing: 0.0135em;
}

@mixin full-width {
	height: auto;
	width: 100%;
}

@mixin clearfix {
	&:before,
	&:after {
		content: ' ';
		display: table;
	}

	&:after {
		clear: both;
	}
}

@mixin visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.u-text-small {
	@include text-small;
}

.u-text-medium {
	@include text-medium;
}

.u-text-large {
	@include text-large;
}

.u-text-align-center {
	text-align: center
}

.u-text-sans-serif {
	font-family: $secondary-font;
}
