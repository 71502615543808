html {
	@include fluid-font-size(17px, 22px);

	font-family: $primary-font;
	line-height: $base-line-height;
}

::selection {
	background: #8e8e8e;
	color: $white;
	opacity: 1;
}

h1,
h2,
h3 {
	font-weight: 500;
	margin-top: 1.5rem;

	&:first-child {
		margin-top: 0;
	}
}

h1 {
	@include fluid-font-size(16px, 24px);
}

h2 {
	@include fluid-font-size(14px, 22px);
}

h3 {
	@include fluid-font-size(14px, 20px);
}

p {
	@include fluid-font-size(26px, 42px);

	line-height: 1.46;
}

ul span {
	@include fluid-font-size(12px, 13px);

	font-family: $secondary-font;
	font-weight: 500;
	letter-spacing: 2px;
	text-transform: uppercase;
}

footer {
	a {
		@include fluid-font-size(14px, 15px);

		font-family: $secondary-font;
		font-weight: 400;
		letter-spacing: 0.25px;
	}
}

.tingle-modal-box {
	font-family: $secondary-font;
	font-weight: 400;

	li,
	p {
		@include fluid-font-size(15px, 18px);

		line-height: 1.65;
	}

	a { 
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}

b,
strong {
	font-weight: 500;
}

i,
em {
	font-style: italic;
}
